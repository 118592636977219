<template>
  <div class="main">
    <!-- 面包屑 -->
    <!-- <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农识资讯管理</el-breadcrumb-item>
      <el-breadcrumb-item>农识资讯发布</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
          <div>
            <!-- 搜索 -->
            标题:
            <a-input
              v-model="inputSearch"
              placeholder="请输入标题"
              style="width: 200px"
              allowClear
            />
            <el-button
              @click="handleSearch"
              style="margin-left: 10px"
              size="small"
            >
              搜索
            </el-button>
            <el-button
              class="add-btn"
              type="success"
              @click="$router.push('/addArticle')"
              v-auth="'agriculturalPublish-add'"
              style="margin-left: 10px"
              size="small"
            >
              新增
            </el-button>
          </div>
          <!--文章内容-->
          <!-- <el-col
            :lg="{ span: 24 }"
            :xl="{ span: 24 }"
            style="padding-top: 0px"
          > -->
            <el-table
        style="margin-top: 0; margin-bottom: 0; flex: 1; margin-top: 14px;"
        header-row-class-name="my-table-head-style"
        height="100%" :data="agricultureInfoData">
              <el-table-column prop="title" label="标题">
                <template slot-scope="scope">
                  <span class="ellipsis-text">
                    {{ scope.row.title }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column prop="modifyDate" label="时间" width="140">
                <template slot-scope="scope">
                  <span>{{ scope.row.time | dateFormatFull }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="state" label="状态" width="90">
                <template slot-scope="scope">
                  <a-badge
                    :status="
                      scope.row.state === 'RELEASE' ? 'success' : 'default'
                    "
                  />
                  {{ scope.row.state | StateFiltr }}
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                width="200"
                v-if="
                  authVerify.verify(
                    'agriculturalPublish-cancel,agriculturalPublish-edit,agriculturalPublish-look,agriculturalPublish-delete'
                  )
                "
              >
                <template slot-scope="scope">
                  <a
                    style="margin-right: 5px"
                    @click="handleConfirmPublish(scope.row)"
                    v-auth="'agriculturalPublish-cancel'"
                    >{{
                      scope.row.state === "INIT" ? "确认发布" : "取消发布"
                    }}</a
                  >
                  <a
                    style="margin-right: 5px"
                    @click="
                      $router.push({
                        path: '/editArticle',
                        query: { item: JSON.stringify(scope.row) },
                      })
                    "
                    v-auth="'agriculturalPublish-edit'"
                  >
                    编辑</a
                  >
                  <a
                    style="margin-right: 5px"
                    @click="
                      $router.push({
                        path: '/agriculturalDetail',
                        query: { item: JSON.stringify(scope.row) },
                      })
                    "
                    v-auth="'agriculturalPublish-look'"
                  >
                    查看</a
                  >
                  <a
                    style="margin-right: 5px"
                    @click="handleDelete(scope.row.id)"
                    v-auth="'agriculturalPublish-delete'"
                  >
                    删除</a
                  >
                </template>
              </el-table-column>
            </el-table>

            <!--分页-->
            <el-pagination
              layout="total,sizes, prev, pager, next"
              :page-sizes="[10, 20, 30, 40]"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="totalCount"
              @current-change="handlePageChange"
              @size-change="handleSizeChange"
            />
          <!-- </el-col> -->
    </el-card>
  </div>
</template>

<script>
export default {
  name: "AgriculturalPublish",

  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      //农识资讯列表
      agricultureInfoData: [],
      //屏幕宽度
      screenWidth: document.documentElement.clientWidth,
      //屏幕高度
      screenHeight: document.documentElement.clientHeight - 50,
      inputSearch: null,
    };
  },

  components: {},

  filters: {
    StateFiltr: function (value) {
      if (!value) return "";
      if (value === "RELEASE") {
        return "已发布";
      } else if (value === "INIT") {
        return "未发布";
      }
    },
  },

  mounted() {
    this.getNewsList();
    window.onresize = () => {
      // 定义窗口大小变更通知事件
      this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },

  methods: {
    //获取农识资讯数据
    async getNewsList() {
      const { data: res } = await this.http.news.getNewsList({
        size: this.pageSize,
        current: this.currentPage,
        title: this.inputSearch,
      });
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.agricultureInfoData = res.data.records;
      this.totalCount = res.data.total;
      this.currentPage = res.data.current;
    },

    //分页
    handlePageChange(val) {
      this.currentPage = val;
      this.getNewsList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getNewsList();
    },

    //搜索
    handleSearch() {
      this.getNewsList();
    },

    //确认发布
    handleConfirmPublish(row) {
      let title = "确定发布吗?";
      let data = { id: row.id, state: "RELEASE" };
      let msg = "发布成功";
      if (row.state === "RELEASE") {
        title = "确定取消发布吗?";
        data = { id: row.id, state: "INIT" };
        msg = "取消发布成功";
      }
      this.$confirm({
        title: title,
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: async () => {
          const { data: res } = await this.http.news.updateNews(data);
          if (res.status === 200) {
            this.getNewsList();
            this.$message.success(msg);
          } else {
            this.$message.error(res.msg);
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },

    //删除
    handleDelete(id) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: async () => {
          const { data: res } = await this.http.news.deleteNews(id);
          if (res.status !== 200) {
            this.$message.error(res.msg);
          }
          this.getNewsList();
          this.$message.success(res.msg);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
  },
};
</script>

<style scoped>
.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/*.btn-wrapper {
      display: flex;
    }

    */

/*.add-btn {

    }*/
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
